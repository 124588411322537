<template>
  <div class="submit-page">
    <div class="content">
      <h3>资源上传</h3>
      <div v-if="!array.length">
        <p class="tip">
          支持百度网盘、迅雷云盘、阿里网盘、夸克网盘的资源(一次最多可以上传500条资源)
        </p>
        <textarea v-model="data" :placeholder="placeholder" />
      </div>
      <div v-else>
        <p class="tip">已识别 {{ array.length }} 条资源</p>
        <el-table :data="array" border style="margin-top: 10px" size="mini">
          <el-table-column prop="url" label="链接" />
          <el-table-column prop="pwd" label="提取码" width="80" />
          <el-table-column prop="msg" label="上传结果" width="120" />
        </el-table>
      </div>
      <div class="button-wrap">
        <van-button
          v-if="!array.length"
          type="primary"
          :disabled="!data || loading"
          @click="startParse"
          >解析资源</van-button
        >
        <van-button
          v-if="array.length && !success"
          type="primary"
          :disabled="loading"
          @click="backEdit"
          >返回修改</van-button
        >
        <van-button
          v-if="array.length && !success"
          type="primary"
          :disabled="loading"
          @click="startUpload"
          >立即上传</van-button
        >
        <van-button v-if="success" type="primary" @click="backEdit(true)"
          >继续上传其他资源</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { submitResources } from "@/api/user";
export default {
  data() {
    return {
      data: "",
      placeholder:
        "资源示例：\n一条资源一行，资源提取码放在资源链接后面，用英文的“,”隔开\nhttps://pan.quark.cn/s/308ff0dec8ad\nhttps://www.alipan.com/s/aq7HG77ZzDc\nhttps://pan.baidu.com/s/1HtGP6u7zVKNap0J3b10mVA,kia2\nhttps://pan.xunlei.com/s/VNqm8B9QMYm_Xlp4J0EN1x6wA1,htx7",
      loading: false,
      array: [],
      success: false,
    };
  },
  methods: {
    backEdit(reset) {
      this.array = [];
      this.success = false;
      if (reset) {
        this.data = "";
      }
    },
    async startParse() {
      this.array = this.data
        .split(/\r\n|[\n\r]/)
        .filter((item) => !!item)
        .map((item) => item.trim())
        .map((item) => {
          const arr = item.split(/,|，/);
          return {
            url: (arr[0] || "").trim(),
            pwd: (arr[1] || "").trim(),
            msg: "等待上传",
          };
        })
        .filter((item) => !!item.url)
        .slice(0, 500);
    },
    async startUpload() {
      this.loading = true;
      const res = await submitResources(this.array);
      if (res.code === -1) {
        return this.$toast.fail("上传失败");
      }
      this.array.map((item, index) => {
        item.msg = res.data[index] || "上传成功";
        return item;
      });
      this.loading = false;
      this.success = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.content {
  margin: 20px auto;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fff;
  h3 {
    text-align: center;
  }
  .tip {
    margin-top: 10px;
  }
  textarea {
    width: 100%;
    padding: 5px 10px;
    min-height: 440px;
    border-radius: 2px;
    margin: 10px auto;
    border: 1px solid #ccc;
    transition: all ease 0.2s;
    &:focus {
      border-color: $theme-color;
    }
  }
  .button-wrap {
    margin-top: 20px;
    text-align: center;
    .van-button {
      width: 150px;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
